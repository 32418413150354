import React, { useEffect, useState } from "react"
import { Box, Heading } from "grommet"
import { Layout, LoadingWithText } from "../../../components"
import {
  goHome,
  sleep,
  navigate,
  goDashboard,
  reportError,
  debug,
} from "../../../utils"
import { useAuth0 } from "../../../hooks"
let callbackExecuteCount = 0

const Page = () => {
  const [status, setStatus] = useState("Login...")
  const [isLoading, setIsLoading] = useState(true)
  const auth0 = useAuth0()
  useEffect(() => {
    const init = async () => {
      const { loading } = auth0
      // ensure has inited
      // login
      if (loading === false) {
        // check if already inited
        if (callbackExecuteCount > 0) {
          return
        }
        callbackExecuteCount++
        const { auth0Client, setIsAuthenticated, setUser } = auth0
        const handleRedirectCallback = async () => {
          callbackExecuteCount++
          const { appState } = await auth0Client.handleRedirectCallback()
          debug("appState: %o", appState)
          const user = await auth0Client.getUser()
          setIsAuthenticated(true)
          setUser(user)
          return {
            user,
            appState,
          }
        }
        try {
          const { appState } = await handleRedirectCallback()
          setIsLoading(false)
          setStatus(`Success to login your account`)
          if (appState && appState.targetUrl) {
            navigate(appState.targetUrl)
          } else {
            goDashboard()
          }
        } catch (error) {
          // login fail
          let message = error.message || "There is some problem at login"
          message = `${message}, You will be redirected to the home page soon...`
          setIsLoading(false)
          setStatus(message)
          reportError(error)
          await sleep(3000)
          // redirect to home
          goHome()
        }
      }
    }
    init()
  }, [auth0])

  return (
    <Layout pure>
      <Box height="100vh" justify="center" align="center">
        {isLoading ? (
          <LoadingWithText text="Login..."></LoadingWithText>
        ) : (
          <Box align="center" pad={{ top: "medium" }}>
            <Heading level={3}>{status}</Heading>
          </Box>
        )}
      </Box>
    </Layout>
  )
}
export default Page
